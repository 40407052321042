import React, { useState, useContext, createContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Homepage from './Pages/Homepage';
import Login from './Pages/Login';
import Trades from './Pages/Trades';
import Orders from './Pages/Orders';
import OrderPage from './Pages/OrderPage';
import Clients from './Pages/Clients';
import Strategy from './Pages/Strategy';
import ClientPage from './Pages/ClientPage';
import CalendarEvents from './Pages/CalendarEvents';
import AdminPanel from './Pages/AdminPanel';
import UserPanel from './Pages/UserPanel';
import PrivateRoute  from './AuthComponents/PrivateRoute';
import LoginRoute  from './AuthComponents/LoginRoute';
import AdminRoute  from './AuthComponents/AdminRoute';
import Sidebar from './Components/Sidebar';
import { AuthLoginInfo }  from './AuthComponents/AuthLogin';

export const LTPContext = createContext({"NIFTY":0,"BANKNIFTY":0,"FINNIFTY":0});

function App() {
    const ctx = useContext(AuthLoginInfo);
    console.log(ctx)
    const [globalLTP, setGlobalLTP] = useState({"NIFTY":0,"BANKNIFTY":0,"FINNIFTY":0});
    return (
      <LTPContext.Provider value={{globalLTP, setGlobalLTP}}>
      <BrowserRouter>
        <Sidebar>
          </Sidebar>
            <Routes>
              <Route path='/' exact element={
                  <PrivateRoute>
                    <Homepage />
                  </PrivateRoute>
                } />
                <Route path='/trades' element={
                    <PrivateRoute>
                      <Trades />
                    </PrivateRoute>
                  } />                
                <Route path='/orders' element={
                    <PrivateRoute>
                      <Orders />
                    </PrivateRoute>
                  } />
                <Route path='/orders/:orderId' element={
                    <PrivateRoute>
                      <OrderPage />
                    </PrivateRoute>
                  } />
                <Route path='/strategy' element={
                    <PrivateRoute>
                      <Strategy />
                    </PrivateRoute>
                  } />                  
                <Route path='/clients' element={
                    <PrivateRoute>
                      <Clients />
                    </PrivateRoute>
                  } />
                <Route path='/clients/:clientId' element={
                    <PrivateRoute>
                      <ClientPage />
                    </PrivateRoute>
                  } />
                <Route path='/calendar' element={
                    <PrivateRoute>
                      <CalendarEvents />
                    </PrivateRoute>
                  } />
                <Route path='/adminPannel' element={
                    <AdminRoute>
                      <AdminPanel />
                    </AdminRoute>
                  } />
                <Route path='/userPannel' element={
                    <PrivateRoute>
                      <UserPanel />
                    </PrivateRoute>
                  } />                  
              <Route path='/login' element={
                  <LoginRoute>
                    <Login />
                  </LoginRoute>
                } />
            </Routes>
      </BrowserRouter>
      </LTPContext.Provider>       
    );
}

export default App;
