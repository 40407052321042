// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminPanelWrap {
  height: 95%;
  width: 95%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.adminPanelHeader {
    height: 10%;
    width: 100%;
}
.adminNav {
  width: 100%;
}
.adminNavList {
  position: relative;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #F4F6F8;
  border: 2px solid #E2E0E3;
  border-radius: 10px;
  padding: 15px 5px;
}
.adminNavItem {
  list-style-type: none;
  margin: 0 15px;
  padding: 5px 10px;
  font-size: 16px;
  transition: color .15s ease-in-out, background-color .15s ease-in-out;
  cursor: pointer;
  border-radius: 10px;
}
.adminNavItem:hover {
  color: #EF5F63;
}
.adminNavItem.active-setting {
  color: #fff;
  background-color: #EF5F63;

}


/* responsive */

@media screen and (max-width: 1024px) {
  .adminPanelWrap {
    overflow: unset;
    overflow-x: hidden;
  }
  .adminPanelHeader {
    text-align: center;
  }
  .addNewUserWrap {
    justify-content: center !important;
  }
  .usersColumn {
    flex-direction: column !important;
  }
  .usersInfo {
    width: 100% !important;
    margin: 0 !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Styles/adminPanel.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,UAAU;EACV,gBAAgB;EAChB,aAAa;EACb,sBAAsB;AACxB;AACA;IACI,WAAW;IACX,WAAW;AACf;AACA;EACE,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;EACzB,yBAAyB;EACzB,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,qBAAqB;EACrB,cAAc;EACd,iBAAiB;EACjB,eAAe;EACf,qEAAqE;EACrE,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;AACA;EACE,WAAW;EACX,yBAAyB;;AAE3B;;;AAGA,eAAe;;AAEf;EACE;IACE,eAAe;IACf,kBAAkB;EACpB;EACA;IACE,kBAAkB;EACpB;EACA;IACE,kCAAkC;EACpC;EACA;IACE,iCAAiC;EACnC;EACA;IACE,sBAAsB;IACtB,oBAAoB;EACtB;AACF","sourcesContent":[".adminPanelWrap {\n  height: 95%;\n  width: 95%;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n}\n.adminPanelHeader {\n    height: 10%;\n    width: 100%;\n}\n.adminNav {\n  width: 100%;\n}\n.adminNavList {\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  text-align: center;\n  align-items: center;\n  justify-content: center;\n  background-color: #F4F6F8;\n  border: 2px solid #E2E0E3;\n  border-radius: 10px;\n  padding: 15px 5px;\n}\n.adminNavItem {\n  list-style-type: none;\n  margin: 0 15px;\n  padding: 5px 10px;\n  font-size: 16px;\n  transition: color .15s ease-in-out, background-color .15s ease-in-out;\n  cursor: pointer;\n  border-radius: 10px;\n}\n.adminNavItem:hover {\n  color: #EF5F63;\n}\n.adminNavItem.active-setting {\n  color: #fff;\n  background-color: #EF5F63;\n\n}\n\n\n/* responsive */\n\n@media screen and (max-width: 1024px) {\n  .adminPanelWrap {\n    overflow: unset;\n    overflow-x: hidden;\n  }\n  .adminPanelHeader {\n    text-align: center;\n  }\n  .addNewUserWrap {\n    justify-content: center !important;\n  }\n  .usersColumn {\n    flex-direction: column !important;\n  }\n  .usersInfo {\n    width: 100% !important;\n    margin: 0 !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
