// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bodyWrap {
  position: fixed;
}
.bodyPopupWrap {
  background: rgba(46 ,46 ,46 , 0.2);
}
.popup {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  position: relative;
  padding: 32px;
  width: 100%;
  height: 90%;
  max-height: 900px;
  max-width: 800px;
  background-color: #fff;
  border-radius: 15px;

}

.popup-inner .close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
	color: #EF5F63;
	border: none;
	padding: 0;
	cursor: pointer;
	outline: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Styles/popup.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,kCAAkC;AACpC;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,WAAW;EACX,iBAAiB;EACjB,gBAAgB;EAChB,sBAAsB;EACtB,mBAAmB;;AAErB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,gBAAgB;CACjB,cAAc;CACd,YAAY;CACZ,UAAU;CACV,eAAe;CACf,gBAAgB;AACjB","sourcesContent":[".bodyWrap {\n  position: fixed;\n}\n.bodyPopupWrap {\n  background: rgba(46 ,46 ,46 , 0.2);\n}\n.popup {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.popup-inner {\n  position: relative;\n  padding: 32px;\n  width: 100%;\n  height: 90%;\n  max-height: 900px;\n  max-width: 800px;\n  background-color: #fff;\n  border-radius: 15px;\n\n}\n\n.popup-inner .close-btn {\n  position: absolute;\n  top: 16px;\n  right: 16px;\n  background: none;\n\tcolor: #EF5F63;\n\tborder: none;\n\tpadding: 0;\n\tcursor: pointer;\n\toutline: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
