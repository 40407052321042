// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clientsTableWrap .addOrderWrap {
  width: 100%
}

.tableResultsWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.resultsSpan {
  color: #979CA1;
  font-weight: 300;
}

.resultsBold {
  color: #151E29;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Styles/clients.css"],"names":[],"mappings":"AAAA;EACE;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".clientsTableWrap .addOrderWrap {\n  width: 100%\n}\n\n.tableResultsWrap {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.resultsSpan {\n  color: #979CA1;\n  font-weight: 300;\n}\n\n.resultsBold {\n  color: #151E29;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
