import React from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
//import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export const SidebarData = [
  {
    title: "Dashboard",
    icon: <HomeRoundedIcon />,
    link: "/"
  },
  {
    title: "Strategy",
    icon: <DashboardCustomizeOutlinedIcon />,
    link: "/strategy"
  },    
  {
    title: "Trades",
    icon: <ContentPasteRoundedIcon />,
    link: "/trades"
  },  
  // {
  //   title: "Orders",
  //   icon: <ContentPasteRoundedIcon />,
  //   link: "/orders"
  // },

  {
    title: "Clients",
    icon: <PeopleOutlineRoundedIcon />,
    link: "/clients"
  },
  // {
  //   title: "Calendar",
  //   icon: <TodayRoundedIcon />,
  //   link: "/calendar"
  // },
  {
    title: "Admin",
    icon: <AdminPanelSettingsRoundedIcon />,
    link: "/adminPannel",
    role: "admin"
  },
  {
    title: "User",
    icon: <ManageAccountsIcon />,
    link: "/userPannel",
  }  
];
