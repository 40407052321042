// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-page-wrapper {
  display: table;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000;
}

.loading-page {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.spinner {
  display: inline-block;
  width: 100px;
  height: 100px;
  background-color: white;
  border: 8px solid #EF5F63;
  border-right-color: transparent;
  border-radius: 50%;
  animation: rotate-spinner  .5s  infinite linear;
}

@keyframes rotate-spinner {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/AuthComponents/Styles/loadingPage.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,sBAAsB;AACxB;AACA;EACE,qBAAqB;EACrB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,yBAAyB;EACzB,+BAA+B;EAC/B,kBAAkB;EAClB,+CAA+C;AACjD;;AAEA;EACE;IACE,oBAAoB;EACtB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loading-page-wrapper {\n  display: table;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  z-index: 1000;\n}\n\n.loading-page {\n  display: table-cell;\n  text-align: center;\n  vertical-align: middle;\n}\n.spinner {\n  display: inline-block;\n  width: 100px;\n  height: 100px;\n  background-color: white;\n  border: 8px solid #EF5F63;\n  border-right-color: transparent;\n  border-radius: 50%;\n  animation: rotate-spinner  .5s  infinite linear;\n}\n\n@keyframes rotate-spinner {\n  from {\n    transform: rotate(0);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
