import React, { useState, useContext, useMemo } from "react";
import "./Styles/sidebar.css";
import axios from "axios";
import { SidebarData } from "./SidebarData";
import { NavLink } from "react-router-dom";
import logo from "../Assets/Images/logo2.png";
import { AuthLoginInfo } from "./../AuthComponents/AuthLogin";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ReconnectingWebSocket from 'reconnecting-websocket';
import configData from "../Pages/config.json";
import { LTPContext } from "../App";

var ws1 = new ReconnectingWebSocket(configData.SOCKET_URI);

const logout = () => {
  axios
    .get(configData.BACKEND_URL+"/logout", {
      withCredentials: true,
    })
    .then((res) => {
      if (res.data === "success") {
        window.location.href = "/login";
      }
    });
};

const terminateAll = () => {
    var event_data = {'event':'stop_all', 'message':'Terminate All Strategy'}
    ws1.send(JSON.stringify(event_data))
};

const terminateSquareOffAll = () => {
    var event_data = {'event':'terminate_all', 'message':'Terminate and SquareOFf All Strategy'}
    ws1.send(JSON.stringify(event_data))
};

const NavbarSection = ({ ctx, globalLTP, sidebarCollapse, handleOnClick }) => {
  const HamburgerMenu = ({ handleOnClick }) => {
    return (
      <button
        className={`hamburgerMenu ${
          sidebarCollapse ? "hamburgerMenuOpen" : "hamburgerMenuClosed"
        }`}
        onClick={() => handleOnClick()}
      >
        <div />
        <div />
        <div />
      </button>
    );
  };


  return (
    <div className="navbar">
      <div className="navbarWrap">
        <div className="navbarRow">
          <HamburgerMenu handleOnClick={handleOnClick} /> 
 
          {/* NIFTY: {globalLTP['NIFTY']} BANKNIFTY: {globalLTP['BANKNIFTY']} FINNIFTY: {globalLTP['FINNIFTY']}                       */}
                   
        </div>

        <div className="navbarRowLast">
          {
            //if user is logged in
            ctx && (
              <div className="userNavbar ">
                <div className="globalltp">
                </div> 
                <div>
                <button className="terminateWithoutSquareOffButton" onClick={() => {terminateAll();}}> <PowerSettingsNewIcon /></button>                    
                <button className="terminateSquareOffButton" onClick={() => {terminateSquareOffAll();}}> <PowerSettingsNewIcon /> </button>                  
                </div>               
                <div className="userLogo">
                  <AccountCircleRoundedIcon />
                </div>
                <div className="userLogged">Logged as: {ctx.username}</div>
                <div
                  className="navbarFlex"
                  onClick={() => {
                    logout();
                  }}
                >
                  <div className="userLogo">
                    <LogoutRoundedIcon className="maincolor clickable" />
                  </div>
                  <div className="logout clickable">Logout</div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

const SidebarSection = ({ ctx, globalLTP, sidebarCollapse }) => {
  return (
    <div
      className={`Sidebar ${sidebarCollapse ? "SidebarOpen" : "SidebarClosed"}`}
    >
      <div className="SidebarLogoWrap">
        <div className="SidebarLogo">
          <img src={logo} className="logo" alt="" /> 
        </div>
      </div>

      <ul className="SidebarList">
        {SidebarData.map((val, key) => {
          if (val?.role !== undefined && val?.role !== ctx?.role) {
            return null;
          }
          return (
            <NavLink
              to={val.link}
              key={key}
              className={({ isActive }) =>
                isActive ? "sidebar-active-link" : "sidebar-link"
              }
            >
              <li className="SidebarRow">
                <div className="RowIcon">{val.icon}</div>
                {/* <div className="RowTitle">{val.title}</div> */}
              </li>
            </NavLink>
          );
        })}
      </ul>
    </div>
  );
};

function Sidebar() {
  const ctx = useContext(AuthLoginInfo);
  const [sidebarCollapse, setSidebarCollapse] = useState(true);
  const handleSidebarCollapse = () => {
    sidebarCollapse ? setSidebarCollapse(false) : setSidebarCollapse(true);
  };
  const {globalLTP, setGlobalLTP} = useContext(LTPContext); 

    // ws1.addEventListener("message", (e) => {
    //   // console.log("ajsoas", e)
    //   // const li = document.createElement("li")
    //   // li.innerText = e.data
    //   // ls.appendChild(li)
    //   console.log(e.data); 
    //   var data = JSON.parse(e.data);
    //   var global_ltp ="";
    //   if(data['event'] === 'ltp_data') {
    //     setGlobalLTP({"NIFTY":data['ltp']['NIFTY'],"BANKNIFTY":data['ltp']['BANKNIFTY'],"FINNIFTY":data['ltp']['FINNIFTY']})
    //   }
    // });
  
  return (
    <div className="SidebarWrapper">
      <NavbarSection ctx={ctx} globalLTP={globalLTP} handleOnClick={handleSidebarCollapse} sidebarCollapse={sidebarCollapse} />
      <SidebarSection ctx={ctx} globalLTP={globalLTP} sidebarCollapse={sidebarCollapse} />
    </div>
  );
}

export default Sidebar;
