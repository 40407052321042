import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./Styles/order.css";
import 'react-tooltip/dist/react-tooltip.css'
import { AuthLoginInfo } from "./../AuthComponents/AuthLogin";
import SearchBar from "../Components/SearchBar";
import Pagination from "../Components/Pagination";
import ReadMoreRoundedIcon from "@mui/icons-material/ReadMoreRounded";
import configData from "./config.json";
import { Tooltip } from 'react-tooltip'

function Trades() {
  const [newOrderSubmitted, setNewOrderSubmitted] = useState(false);

  const [ordersData, setOrdersData] = useState([]);
   const [filteredData, setFilteredData] = useState([]);

  const [filterOrders, setFilterOrders] = useState("");
  //#const [filterId, setFilterId] = useState("");
  const [filterActive, setFilterActive] = useState(1);

  const ctx = useContext(AuthLoginInfo);

  console.log(ordersData)

  useEffect(() => {
    setNewOrderSubmitted(false);
    axios
      .get(configData.BACKEND_URL+"/trades", { withCredentials: true })
      .then((res) => {
        if (res.data != null) {
          setOrdersData(res.data);
          setFilteredData(res.data);
        }
      });
  }, [newOrderSubmitted]);

  const handleSearchChange = (newFilteredData) => {
    setFilteredData(newFilteredData);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30;
  const totalOrders = filteredData.length;
  const computedOrders = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    (currentPage - 1) * itemsPerPage + itemsPerPage
  );

  return (
    
    <div className="bodyWrap">
      <div className="contentOrderWrap">
        <div className="leftSide">
          <h1>Trades</h1>
          <Pagination
            total={totalOrders}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
          <div className="orderNavWrap">
            <div className="orderNav">
              <ul>
                <li
                  className={`${filterActive === 1 ? "active" : ""}`}
                  onClick={() => {
                    setFilterOrders("");
                    setFilterActive(1);
                  }}
                >
                  All Trades
                </li>
                <li
                  className={`${filterActive === 2 ? "active" : ""}`}
                  onClick={() => {
                    setFilterOrders("OPEN");
                    setFilterActive(2);
                  }}
                >
                  Open
                </li>
                <li
                  className={`${filterActive === 3 ? "active" : ""}`}
                  onClick={() => {
                    setFilterOrders("CLOSE");
                    setFilterActive(3);
                  }}
                >
                  Close
                </li>
              </ul>
            </div>
            <div className="addOrderWrap">
              <SearchBar
                data={ordersData}
                handleSearchChange={handleSearchChange}
                dataType="trades"
                status={filterOrders}
              />
            </div>
          </div>
          <div className="orderWrap">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>ST</th>                  
                  <th>Symbol</th>
                  <th>CP</th>
                  <th className="alignright">QTY</th>                  
                  <th className="alignright">EntPr</th>
                  <th className="alignright">ExtPr</th>
                  <th>Entry Time</th>
                  <th>Exit Time</th> 
                  <th>Status</th>
                  <th className="alignright">Pnl</th> 
                  <th>Note</th>                   
                                                    
                </tr>
              </thead>
              <tbody>
                {computedOrders?.map((order) => {
                  return (
                    <tr key={order.id}>
                      <td><font className="maincolor">#</font>{order.id}</td>
                      <td>{order.strategy_name}</td>                      
                      <td>{order.symbol}</td>
                      <td>{order.cp}</td>
                      <td className="alignright">{order.qty}</td>
                      <td className="alignright">{order.enp}</td>
                      <td className="alignright">{order.exp}</td>
                      <td>{order.ent.split("00")[0]}</td>
                      <td>{(order.ext==null)?'':order.ext.split("00")[0]}</td>
                      <td>{order.status}</td>                                                                                                                                                          
                      <td className="alignright">{order.pnl}</td>
                      <td>
                      <Tooltip id={"mytooltip"+order.id} />
                      <a data-tooltip-id={"mytooltip"+order.id} data-tooltip-content={order.remark}>{(order.remark=='')?'':'View'}</a>
                      </td>                      
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
      </div>
    </div>


  );
}

export default Trades;
