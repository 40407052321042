// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  margin-bottom: 16px;
  text-align: center;
  align-items: center;
}

.pagination-item {
  position: relative;
  display: block;
  padding: 2px 10px;
  margin-left: -1px;
  line-height: 1.25;
  color: #979CA1;
  cursor: pointer;
  border-radius: 8px;
}
.pagination-item:hover {
    z-index: 2;
    color: #151E29;
    text-decoration: none;
}
.pagination-item-icon {
  background-color: #F4F4F4;

}
`, "",{"version":3,"sources":["webpack://./src/Components/Styles/pagination.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;EACd,eAAe;EACf,kBAAkB;AACpB;AACA;IACI,UAAU;IACV,cAAc;IACd,qBAAqB;AACzB;AACA;EACE,yBAAyB;;AAE3B","sourcesContent":[".pagination {\n  display: flex;\n  padding-left: 0;\n  list-style: none;\n  border-radius: 0.25rem;\n  margin-bottom: 16px;\n  text-align: center;\n  align-items: center;\n}\n\n.pagination-item {\n  position: relative;\n  display: block;\n  padding: 2px 10px;\n  margin-left: -1px;\n  line-height: 1.25;\n  color: #979CA1;\n  cursor: pointer;\n  border-radius: 8px;\n}\n.pagination-item:hover {\n    z-index: 2;\n    color: #151E29;\n    text-decoration: none;\n}\n.pagination-item-icon {\n  background-color: #F4F4F4;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
