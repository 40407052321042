//import react, { useState, useEffect } from "react";
import { useState, useEffect } from "react";
import React from "react";

function SearchBar({ data, handleSearchChange, dataType, status }) {
  const [searchInput, setSearchInput] = useState("");

  const clientsDataFiltered = () => {
    const dataFiltered = data
      ?.filter((val) => {
        if (
          [val.client.toLowerCase(), val.client_id + ""].some((r) =>
            r.includes(searchInput.toLowerCase())
          )
        ) {
          return val;
        }
      })
      .reverse();
    console.log('clientsDataFiltered ',dataFiltered);      
    handleSearchChange(dataFiltered);
  }

  const ordersDataFiltered = () => {
    const dataFiltered = data
      ?.filter((val) => {
        if (
          val.status.includes(status) &&
          [val.client.toLowerCase(), val.id + ""].some((r) =>
            r.includes(searchInput.toLowerCase())
          )
        ) {
          return val;
        }
      })
      .reverse();
    console.log('ordersDataFiltered ',dataFiltered);      
    handleSearchChange(dataFiltered);
  }

  const tradesDataFiltered = () => {
    const dataFiltered = data
      ?.filter((val) => {
        console.log('status: ',status);
        if (val.status.includes(status.toUpperCase()))
        {
          console.log('valval ',val);
          return val;
        } else if (searchInput !=="" && val.symbol.includes(searchInput)) 
        {
          console.log('valval ',val);
          return val;
        } else if(searchInput == '' && status =='') {
          console.log('valval ',val);
          return val;
        }
      })
      .reverse();
    console.log('tradesDataFiltered ',dataFiltered);      
    handleSearchChange(dataFiltered);
  }  

  const strategyDataFiltered = () => {
    const dataFiltered = data
      ?.filter((val) => {
        console.log('status: ',status);
        if (val.status.includes(status))
        {
          console.log('valval ',val);
          return val;
        } else if (searchInput !=="" && val.symbol.includes(searchInput)) 
        {
          console.log('valval ',val);
          return val;
        } else if(searchInput == '' && status =='') {
          console.log('valval ',val);
          return val;
        }
      })
      .reverse();
      console.log('strategyDataFiltered ',dataFiltered);
      handleSearchChange(dataFiltered);
  } 

  useEffect(() => {
    if(dataType === "orders") {
        console.log('ordersDataFiltered Called');        
        ordersDataFiltered();
    }
    if(dataType === "clients") {
        console.log('clientsDataFiltered Called');      
        clientsDataFiltered();
    }
    if(dataType === "trades") {
        console.log('tradesDataFiltered Called');      
        tradesDataFiltered();
    } 
    
    if(dataType === "strategy") {
        console.log('strategyDataFiltered Called');
        strategyDataFiltered();
    }      
      
  }, [searchInput, dataType, status]);


  return (
    <>
      <input
        type="text"
        placeholder="Search by name or ID"
        onChange={(e) => setSearchInput(e.target.value)}
        value={searchInput}
      />
    </>
  );
}

export default SearchBar;
